import React from "react";

const PageNotFound = () => {
    return(
        <div>
            404
        </div>
    )
}

export default PageNotFound